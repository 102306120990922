@import "./style/mixins.scss";
@import "./style/colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
body,html{
  background-color: rgb(240,242,245);
  overflow-y: auto!important;
}
body {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif !important;
  iframe{
    position: inherit!important;
  }
}
main{
  overflow-y: auto!important;
}

html {
  font-size: 62.5%; // 62.5% = 10px , 1rem=10px
  @include respond(tab) {
    font-size: 56.25%;
  }
}

.layout {
  min-height: 100vh;
}
#root > section > main > div > div > section > main{
  height: 100vh;
}

.ant-layout {
  background-color: #F9F9F9;
}

#root > section > main > div{
  height: 100vh;
}

.ant-typography{
  @media (max-width: 788px) {
    max-width: 90%;
  }
}

.hiddenOver{
  overflow: hidden!important;
}